<template>
  <el-scrollbar class="social-embed is-full-height">
    <div
      v-loading="isLoading"
      class="is-full-height"
    >
      <div v-if="!feedIsEmpty">
        <ul class="instagram_feed columns is-mobile is-gapless is-multiline">
          <li
            v-for="image in images"
            :key="image.url"
            class="insta_thumb column is-4-mobile is-6-tablet is-6-desktop"
          >
            <a
              :href="image.url"
              target="_blank"
            >
              <img :src="image.thumbnail">
            </a>
          </li>
        </ul>
      </div>
      <div
        v-else
        class="has-text-centered"
      >
        <img :src="$asset('/images/general/no-img-placeholder.png')">
        <span>{{ $t('general.no_more_items') }}</span>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'

export default {
  props: {
    newsroomId: {
      type: String,
      required: true
    },
    limit: {
      type: Number,
      default: 10
    },
    handleId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      images: [],
      isLoading: true
    }
  },

  computed: {
    feedIsEmpty () {
      return !this.isLoading && !this.images.length
    }
  },

  watch: {
    handleId: {
      immediate: true,
      handler: 'fetchFeed'
    }
  },

  methods: {
    fetchFeed () {
      this.isLoading = true
      NewsroomService.fetchSocialHandleFeed(this.newsroomId, this.handleId)
        .then((images) => {
          this.images = images
            .slice(0, this.limit)
            .map((post) => {
              const url = post.url || ''
              const thumbnail = post.thumbnail || ''
              return { url, thumbnail }
            })
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss">
@import "~utils";

.instagram_feed {
  padding: 3px;

  .insta_thumb {
    padding: 3px !important;

    a {
      display: block;
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: $hf-search-plus;
        font-family: $icomoon-font-family;
        font-size: 4rem;
        color: $white;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparentize($black, .6);
        opacity: 0;
        transition: .15s ease-in;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }

      img {
        margin: auto;
        display: block;
      }
    }
  }
}
</style>
