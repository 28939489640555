<template>
  <div class="NewsroomSocialLinksEmbeds">
    <div class="columns is-multiline is-variable-grid is-1">
      <div
        v-for="handle in handlesToRender"
        :key="handle.id"
        class="column is-4 is-flex is-column"
        :data-testid="`newsroomSocial--${handle.type}`"
      >
        <div class="social-feed-title">
          <v-icon size="2x" :type="handle.type" />
          {{ $t(`general.` + handle.type) }}
        </div>

        <component
          :is="handle.type + '-embed'"
          :handle="handle.handle"
          :handle-id="handle.id"
          :newsroom-id="newsroomId"
          class="max-width-full"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TwitterEmbed from '@/components/newsroom/embeds/TwitterEmbed'
import FacebookEmbed from '@/components/newsroom/embeds/FacebookEmbed'
import InstagramEmbed from '@/components/newsroom/embeds/InstagramEmbed'

/**
 * @module NewsroomSocialLinksEmbeds
 */
export default {
  name: 'NewsroomSocialLinksEmbeds',
  components: {
    TwitterEmbed,
    FacebookEmbed,
    InstagramEmbed
  },
  props: {
    newsroomId: {
      type: String,
      default: ''
    },
    handles: {
      type: Array,
      required: true
    }
  },
  computed: {
    handlesToRender () {
      return this.handles.filter(h => ['instagram', 'facebook', 'twitter'].includes(h.type))
    }
  }
}
</script>
