<template>
  <div v-if="video.type">
    <div
      v-if="!error && videoComponent"
      :class="'video-from-service--' + video.type"
      class="responsive-embed widescreen video-from-service"
    >
      <component
        :is="videoComponent"
        :video-id="video.id"
        :player-width="width"
        :player-height="height"
        @error="handleError"
      />
    </div>

    <div v-else class="has-text-danger has-text-weight-bold has-text-centered p-v-3">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer as VimeoPlayer } from 'vue-vimeo-player/dist/bundle.es'
import { YouTubePlayer } from 'vue-youtube-embed'

export default {
  components: {
    vimeoEmbed: VimeoPlayer,
    youtubeEmbed: YouTubePlayer
  },
  props: {
    video: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      width: '100%',
      height: 390,
      error: ''
    }
  },
  computed: {
    videoComponent () {
      const type = this.video.type
      if (!['youtube', 'vimeo'].includes(type)) return null
      return type + 'Embed'
    }
  },
  methods: {
    handleError (e) {
      if (typeof e.message === 'string' && e.message.includes('is not embeddable')) {
        this.error = this.$t('errors.newsroom_embedded_video_restricted')
      } else {
        this.error = this.$t('errors.newsroom_embedded_video_error')
      }
    }
  }
}
</script>

<style>
.video-from-service--vimeo iframe {
  width: 100%;
}
</style>
