<template>
  <el-scrollbar class="social-embed">
    <div
      v-if="warning"
      class="message is-warning"
    >
      <div class="message-body">
        {{ $t('components.facebook_embed.empty_warning') }}
      </div>
    </div>
    <div
      id="facebook-embed"
      ref="tpl"
      :data-href="url"
      class="fb-page"
      data-height="800"
      data-tabs="timeline"
      data-small-header="true"
      data-adapt-container-width="true"
      data-hide-cover="true"
      data-show-facepile="false"
    />
  </el-scrollbar>
</template>

<script>
import { loadScript } from '@/shared/utils.js'
import { asyncTimeout, removeElementChildren } from '@hypefactors/shared/js/utils'

export default {
  props: {
    handle: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      warning: false,
      retries: 0
    }
  },

  computed: {
    url () {
      return `https://www.facebook.com/${this.handle}`
    }
  },

  watch: {
    url: 'renderFeed'
  },

  mounted () {
    this.loadFacebookScript()
      .then(this.renderFeed())
      .catch(e => { })
  },

  methods: {
    async renderFeed () {
      // try a few times
      do {
        this.retries++
        this.warning = false
        // remove all children of facebook component
        removeElementChildren(this.$refs.tpl)
        await asyncTimeout(500)

        try {
          if (!this.handle) break
          if (!window.FB || !window.FB.XFBML) {
            continue
          }
          window.FB.XFBML.parse(this.$el, (r) => {
            if (!this.$refs.tpl) return
            const boundingRect = this.$refs.tpl.getBoundingClientRect()
            // if the width of the embed is 0, then there was an issue with it
            if (boundingRect.height === 0 || boundingRect.width === 0) {
              this.warning = true
            }
          })
          break
        } catch (err) {
          this.$handleError(err)
        }
      } while (this.retries < 4)
    },
    loadFacebookScript () {
      return loadScript('//connect.facebook.net/en_US/all.js#xfbml=1&version=v2.10&appId=1513796032205721', 'facebook-jssdk')
    }
  }
}
</script>
