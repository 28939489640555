<template>
  <el-scrollbar class="social-embed">
    <div id="twitter-embed" ref="widget" />
  </el-scrollbar>
</template>

<script>
import { checkForAddblocker, loadScript } from '@/shared/utils'

export default {
  props: {
    handle: {
      type: String,
      default: ''
    },
    limit: {
      type: String,
      default: '2'
    }
  },

  watch: {
    handle: {
      immediate: true,
      handler: 'mountTwitter'
    }
  },

  methods: {
    mountTwitter () {
      this.loadTwitterScript()
        .then(this.initWidget)
        .catch(e => {})
    },
    loadTwitterScript () {
      return loadScript('//platform.twitter.com/widgets.js', 'twitter-script')
    },
    initWidget () {
      try {
        window.twttr && window.twttr.widgets && window.twttr.widgets.createTimeline({
          sourceType: 'profile',
          screenName: this.handle
        }, this.$refs.widget, {
          tweetLimit: this.limit,
          chrome: 'noheader,nofooter'
        }).then(el => {
          // Do another check just to be sure if the adblocker delays.
          checkForAddblocker(this.$refs.tpl)
        })
      } catch (err) {}
    }
  }
}
</script>
